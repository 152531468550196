import React, { useState } from "react";
import "./Home.css";

import FetchData from "../FetchData/FetchData";

const Home = () => {
  const F1Title = "F1 News";
  const f1DataRssUrl =
    "https://www.google.com/alerts/feeds/12128546747820960644/11777269632709201141";
  const motoGpTitle = "F1 News";
  const motoGpRssUrl =
    "https://www.google.com/alerts/feeds/12128546747820960644/4100448704162997668";
  const rugbyTitle = "Rugby News";
  const rugbyRssUrl =
    "https://www.google.com/alerts/feeds/12128546747820960644/1370123312021411658";

  return (
    <React.Fragment>
      <div className="home-wrapper">
        <div className="feed-wrapper">
          <FetchData title={F1Title} FetchDataRssUrl={f1DataRssUrl} />
        </div>

        {/* <div className="feed-wrapper">
          <FetchData title={motoGpTitle} FetchDataRssUrl={motoGpRssUrl} />
        </div>
        <div className="feed-wrapper">
          <FetchData title={rugbyTitle} FetchDataRssUrl={rugbyRssUrl} />
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Home;
