import React, { useState, useEffect } from "react";
import "./FetchData.css";
import axios from "axios";
const shortid = require("shortid");
var convert = require("xml-js");

const FetchData = props => {
  const title = props.title;
  const FetchDataRssUrl = props.FetchDataRssUrl;
  const [items, setItems] = useState([]);

  useEffect(() => {
    // const apiRssToJson = "https://api.rss2json.com/v1/api.json?rss_url=";
    // const apiRssToJsonKey = "&api_key=tyziilu7xrp7w8tn8ptp1hkapc8wplrbw4lcxjgw";

    axios
      .get(FetchDataRssUrl)
      .then(res => {
        console.log(convert.xml2json(res, { compact: true, spaces: 4 }));
        setItems(res.data.items);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <React.Fragment>
      <h3>{title}</h3>

      {items.map(item => {
        let newItemTitle = item.title.replace(/<b>/, " ");
        let title = newItemTitle.replace(/<\/b>/, " ");
        let newItemDescription = item.description.replace(/<b>/, " ");
        let description = newItemDescription.replace(/<\/b>/, " ");
        return (
          <React.Fragment>
            <div className="fetch-data-wrapper" key={shortid.generate()}>
              <h3>{title}</h3>
              <div>{description}</div>
              <div>{item.pubDate}</div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default FetchData;
